/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS(getInitialState());

export default function remoteUnitsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, name, type, value, params } = action.payload;
      if (name === 'networkType' && value === 'networkType.disabled')
        return INITIAL_STATE;
      if (index !== undefined && type === 'remoteUnits') {
        return state.setIn([index, name], value);
      } else if (name === 'product' || name === 'oem') {
        if (value.includes('000as') || value === 'aras') {
          return state
            .setIn([0, 'type'], 'remote.unit.nox')
            .setIn([0, 'port'], 0);
        }
        if (name === 'oem') {
          return state.setIn([0, 'type'], '').setIn([0, 'port'], '');
        }
        const oldProduct = params.oldProduct;
        if (oldProduct.includes('000as') && !value.includes('000as')) {
          return state.setIn([0, 'type'], '').setIn([0, 'port'], '');
        }
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function getInitialState() {
  const INITIAL_REMOTE_UNITS_JSON = [];
  for (let i = 0; i < 3; i++) {
    INITIAL_REMOTE_UNITS_JSON.push({
      address: '',
      port: '',
      label: '',
      position: i + 1,
      type: ''
    });
  }
  return INITIAL_REMOTE_UNITS_JSON;
}
